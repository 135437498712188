<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />

        <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">         
            <template v-for="(item, key) in list_check_box">               
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                      <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->
                    
                    
                    <!-- Section Detail Start -->

                    <!--General information start-->
                    <div class="content-onglets table" v-if="key == 'detail_general_information' && item.show">
                        <table class="comparateur2 border-top-gray">
                            <tr v-if="data.guidance_id">
                                <th width="20%" class="text-left bg-white">Project number</th>
                                <td class="bg-white text-left"> {{ data.guidance_id }} </td>
                            </tr>
                            <tr v-if="data.status">
                                <th width="20%" class="text-left bg-white">Review status</th>
                                <td class="bg-white text-left"> {{ data.status }} </td>
                            </tr>
                        </table>
                    </div>
                    <!--General information end-->

                    <!--detail_indication start-->
                    <Indication v-if="key == 'detail_indication' && item.show"/>
                    <!--detail_indication end-->

                    <!--detail_funding_request start-->
                    <div class="content-onglets collapse" v-else-if="key == 'detail_funding_request' && item.show" >   
                        <span v-html="data.perc['funding_request']" ></span>                    
                    </div>  
                    <!--detail_funding_request end-->

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show" />
                    <!-- treatment_line end -->

                    <!-- detail_Conclusion start-->
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />
                    <!-- detail_Conclusion end-->

                    <!--pERC recommendation start-->
                    <div class="content-onglets" v-else-if="key == 'detail_perc_recommendation' && item.show">
                        <p v-html="data.perc['perc_recommendation']"></p>
                    </div>    
                    <!--pERC recommendation end--> 

                    <!--Potential next steps for stakeholders start-->
                    <div class="content-onglets" v-else-if="key == 'detail_potential_next_steps' && item.show">
                        <p v-html="data.perc['potential_next_steps']"></p>
                    </div>
                    <!--Potential next steps for stakeholders end-->  

                    <!--Summary of PERC deliberation start-->
                    <div class="content-onglets" v-else-if="key == 'detail_summary_of_perc_deliberation' && item.show">
                        <p v-html="data.perc['summary_of_perc_deliberation']"></p>
                    </div>
                    <!--Summary of PERC deliberation end--> 

                    <!--Evidence in brief start-->
                    <div class="content-onglets" v-else-if="key == 'detail_evidence_in_brief' && item.show">
                        <p v-html="data.perc['evidence_in_brief']"></p>
                    </div>
                    <!--Evidence in brief end-->

                    <!-- detail_contextual_information start -->
                    <div class="content-onglets" v-else-if="key == 'detail_contextual_information' && item.show">
                        <p v-html="data.perc['contextual_information']"></p>
                    </div> 
                    <!-- detail_contextual_information end -->

                    <!-- detail_overall_clinical_benefit_pcodr_review_scope start -->
                    <div class="content-onglets table" v-else-if="key == 'detail_overall_clinical_benefit_pcodr_review_scope' && item.show"> 
                        <div class="fix-table-xl">
                                
                            <div class="box-toggle" v-if="data.perc['overall_clinical_benefit_pcodr_review_scope']">
                                <a class="title title-tb"  @click="overall_clinical_benefit_pcodr_review_scope = !overall_clinical_benefit_pcodr_review_scope">
                                    {{$t('overall_clinical_benefit_pcodr_review_scope')}} 
                                    <em :class="overall_clinical_benefit_pcodr_review_scope ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="overall_clinical_benefit_pcodr_review_scope">
                                    <span v-html="data.perc['overall_clinical_benefit_pcodr_review_scope']"></span>      
                                </section>
                            </div>
                                
                            <div class="box-toggle" v-if="data.perc['overall_clinical_benefit_studies_included']">
                                <a  class="title title-tb"  @click="overall_clinical_benefit_studies_included = !overall_clinical_benefit_studies_included">
                                    {{$t('overall_clinical_benefit_studies_included')}}  
                                    <em :class="overall_clinical_benefit_studies_included ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="overall_clinical_benefit_studies_included">
                                    <span v-html="data.perc['overall_clinical_benefit_studies_included']"></span>      
                                </section>
                            </div>

                            <div class="box-toggle" v-if="data.perc['overall_clinical_benefit_patient_populations']">
                                <a  class="title title-tb"  @click="overall_clinical_benefit_patient_populations = !overall_clinical_benefit_patient_populations">
                                    {{$t('overall_clinical_benefit_patient_populations')}}  
                                    <em :class="overall_clinical_benefit_patient_populations ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="overall_clinical_benefit_patient_populations">
                                    <span v-html="data.perc['overall_clinical_benefit_patient_populations']"></span>        
                                </section>
                            </div>
                            
                            <div class="box-toggle" v-if="data.perc['overall_clinical_benefit_key_efficacy_result']">
                                <a  class="title title-tb"  @click="overall_clinical_benefit_key_efficacy_result = !overall_clinical_benefit_key_efficacy_result">
                                    {{$t('overall_clinical_benefit_key_efficacy_result')}}  
                                    <em :class="overall_clinical_benefit_key_efficacy_result ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="overall_clinical_benefit_key_efficacy_result">
                                    <span v-html="data.perc['overall_clinical_benefit_key_efficacy_result']"></span>       
                                </section>
                            </div>

                            <div class="box-toggle" v-if="data.perc['overall_clinical_benefit_patient_reported_outcomes']">
                                <a  class="title title-tb"  @click="overall_clinical_benefit_patient_reported_outcomes = !overall_clinical_benefit_patient_reported_outcomes">
                                    {{$t('overall_clinical_benefit_patient_reported_outcomes')}}  
                                    <em :class="overall_clinical_benefit_patient_reported_outcomes ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="overall_clinical_benefit_patient_reported_outcomes">
                                    <span v-html="data.perc['overall_clinical_benefit_patient_reported_outcomes']"></span>     
                                </section>
                            </div>

                            <div class="box-toggle" v-if="data.perc['overall_clinical_benefit_duration_of_therapy']">
                                <a  class="title title-tb" @click="overall_clinical_benefit_duration_of_therapy = !overall_clinical_benefit_duration_of_therapy">
                                    {{$t('overall_clinical_benefit_duration_of_therapy')}}  
                                    <em :class="overall_clinical_benefit_duration_of_therapy ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="overall_clinical_benefit_duration_of_therapy">
                                    <span v-html="data.perc['overall_clinical_benefit_duration_of_therapy']"></span>      
                                </section>
                            </div>                            
                            
                            <div class="box-toggle" v-if="data.perc['overall_clinical_benefit_quality_of_life']">
                                <a  class="title title-tb"  @click="overall_clinical_benefit_quality_of_life = !overall_clinical_benefit_quality_of_life">
                                    {{$t('overall_clinical_benefit_quality_of_life')}}  
                                    <em :class="overall_clinical_benefit_quality_of_life ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="overall_clinical_benefit_quality_of_life">
                                    <span v-html="data.perc['overall_clinical_benefit_quality_of_life']"></span>      
                                </section>
                            </div>
                            
                            <div class="box-toggle" v-if="data.perc['overall_clinical_benefit_safety']">
                                <a  class="title title-tb" @click="overall_clinical_benefit_safety = !overall_clinical_benefit_safety">
                                    {{$t('overall_clinical_benefit_safety')}}  
                                    <em :class="overall_clinical_benefit_safety ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="overall_clinical_benefit_safety">
                                    <span v-html="data.perc['overall_clinical_benefit_safety']"></span>        
                                </section>
                            </div>
                            
                            <div class="box-toggle" v-if="data.perc['overall_clinical_benefit_limitations']">
                                <a  class="title title-tb" @click="overall_clinical_benefit_limitations = !overall_clinical_benefit_limitations">
                                    {{$t('overall_clinical_benefit_limitations')}}  
                                    <em :class="overall_clinical_benefit_limitations ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="overall_clinical_benefit_limitations">
                                    <span v-html="data.perc['overall_clinical_benefit_limitations']"></span>      
                                </section>
                            </div>

                            <div class="box-toggle" v-if="data.perc['overall_clinical_benefit_ongoing_trials']">
                                <a  class="title title-tb" @click="overall_clinical_benefit_ongoing_trials = !overall_clinical_benefit_ongoing_trials">
                                    {{$t('overall_clinical_benefit_ongoing_trials')}}  
                                    <em :class="overall_clinical_benefit_ongoing_trials ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="overall_clinical_benefit_ongoing_trials">
                                    <span v-html="data.perc['overall_clinical_benefit_ongoing_trials']"></span>       
                                </section>
                            </div>
                            
                            <div class="box-toggle" v-if="data.perc['overall_clinical_benefit_comparator_information']">
                                <a  class="title title-tb" @click="overall_clinical_benefit_comparator_information = !overall_clinical_benefit_comparator_information">
                                    {{$t('overall_clinical_benefit_comparator_information')}}  
                                    <em :class="overall_clinical_benefit_comparator_information ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="overall_clinical_benefit_comparator_information">
                                    <span v-html="data.perc['overall_clinical_benefit_comparator_information']"></span>       
                                </section>
                            </div> 
                            
                            <div class="box-toggle" v-if="data.perc['overall_clinical_benefit_previously_treated_patients']">
                                <a  class="title title-tb" @click="overall_clinical_benefit_previously_treated_patients = !overall_clinical_benefit_previously_treated_patients">
                                    {{$t('overall_clinical_benefit_previously_treated_patients')}}  
                                    <em :class="overall_clinical_benefit_previously_treated_patients ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="overall_clinical_benefit_previously_treated_patients">
                                    <span v-html="data.perc['overall_clinical_benefit_previously_treated_patients']"></span>       
                                </section>
                            </div> 

                            <div class="box-toggle" v-if="data.perc['overall_clinical_benefit_registered_clinician']">
                                <a  class="title title-tb" @click="overall_clinical_benefit_registered_clinician = !overall_clinical_benefit_registered_clinician">
                                    {{$t('overall_clinical_benefit_registered_clinician')}}  
                                    <em :class="overall_clinical_benefit_registered_clinician ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="overall_clinical_benefit_registered_clinician">
                                    <span v-html="data.perc['overall_clinical_benefit_registered_clinician']"></span>    
                                </section>
                            </div> 

                            <div class="box-toggle" v-if="data.perc['overall_clinical_benefit_need']">
                                <a  class="title title-tb" @click="overall_clinical_benefit_need = !overall_clinical_benefit_need">
                                    {{$t('overall_clinical_benefit_need')}}  
                                    <em :class="overall_clinical_benefit_need ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="overall_clinical_benefit_need">
                                    <span v-html="data.perc['overall_clinical_benefit_need']"></span>       
                                </section>
                            </div>
                        </div>
                    </div>
                    <!-- detail_overall_clinical_benefit_pcodr_review_scope end -->

                    <!--EssaisClinique start-->
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>
                    <!--EssaisClinique end-->   

                    <!--Patient-based values start-->
                    <div class="content-onglets table" v-else-if="key == 'detail_patient_based_values_experiences_patients' && item.show">
                        <div class="fix-table-xl">
                            <div class="box-toggle" v-if="data.perc['patient_based_values_experiences_patients']">
                                <a class="title title-tb"  @click="patient_based_values_experiences_patients = !patient_based_values_experiences_patients">
                                    {{$t('patient_based_values_experiences_patients')}}
                                    <em :class="patient_based_values_experiences_patients ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="patient_based_values_experiences_patients">
                                    <span v-html="data.perc['patient_based_values_experiences_patients']"></span>      
                                </section>
                            </div>

                            <div class="box-toggle" v-if="data.perc['patient_based_values_values_of_patients']">
                                <a class="title title-tb" @click="patient_based_values_values_of_patients = !patient_based_values_values_of_patients">
                                    {{$t('patient_based_values_values_of_patients')}}
                                    <em :class="patient_based_values_values_of_patients ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="patient_based_values_values_of_patients">
                                    <span v-html="data.perc['patient_based_values_values_of_patients']"></span>      
                                </section>
                            </div>

                            <div class="box-toggle" v-if="data.perc['patient_based_values_patient_values_treatment']">
                                <a class="title title-tb" @click="patient_based_values_patient_values_treatment = !patient_based_values_patient_values_treatment">
                                    {{$t('patient_based_values_patient_values_treatment')}}
                                    <em :class="patient_based_values_patient_values_treatment ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="patient_based_values_patient_values_treatment">
                                    <span v-html="data.perc['patient_based_values_patient_values_treatment']"></span>     
                                </section>
                            </div>                        
                        </div> 
                    </div>
                    <!--Patient-based values end-->

                    <!-- detail_PercEffectivenessEvidence start -->
                    <div class="content-onglets table" v-else-if="key == 'detail_PercEffectivenessEvidence' && item.show">
                        <table class="comparateur2 textJustify" v-if="data.perc['perc_effectiveness_evidence'].length > 0">
                            <thead>
                                <tr>
                                    <th width="10%" class="first" >Model type</th> 
                                    <th width="10%" >ICER comparator</th>
                                    <th width="10%" >ICER type</th>
                                    <th width="10%" >ICER basecase (GBP)</th>
                                    <th width="10%" >ICER lower (GBP)</th>
                                    <th width="10%" >ICER upper (GBP)</th>
                                    <th width="10%" >ICER probability </th> 
                                    <th width="30%" class="last">pCODR comment on ICER</th> 
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(value, key) in data.perc['perc_effectiveness_evidence']" :key="'PercEffectivenessEvidence_'+key"> 
                                    <td> {{value['icer_model_type'] ? value['icer_model_type'] :"-"}} </td> 
                                    <td> {{value['icer_comparator'] ? value['icer_comparator'] : "-"}} </td>
                                    <td> {{value['icer_type'] ? value['icer_type'] : "-"}} </td>
                                    <td> {{value['icer_basecase_text'] ? value['icer_basecase_text'] : "-"}}</td>
                                    <td> {{value['icer_lower_text'] ? value['icer_lower_text'] : "-"}}</td>
                                    <td> {{value['icer_upper_text'] ? value['icer_upper_text'] : "-"}}</td>
                                    <td> {{value['icer_probability'] ? value['icer_probability'] : "-"}} </td>
                                    <td class="textJustify">
                                        <div class="expandable textJustify" v-if="value['icer_perc_comment']" v-html="value['icer_perc_comment']"></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <a class="btn btn-warning" style="color:#fff" :href="FULL_BASE_URL+'/pdf/view_pdf_popup/'+ $route.params.id +'/' + $route.params.agency" target="_blank" rel="noopener" > Full analysis</a>
                        <div style="margin-bottom: 10px"></div>                
                    </div>
                    <!-- detail_PercEffectivenessEvidence end -->

                    <!--Economic evaluation start-->
                    <div class="content-onglets table" v-else-if="key == 'detail_economic_evaluation_economic_model_submitted' && item.show">
                        <div class="fix-table-xl">

                            <div class="box-toggle" v-if="data.perc['economic_evaluation_economic_model_submitted']">
                                <a class="title title-tb" @click="economic_evaluation_economic_model_submitted = !economic_evaluation_economic_model_submitted">        
                                    {{$t('economic_evaluation_economic_model_submitted')}}
                                    <em :class="economic_evaluation_economic_model_submitted ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="economic_evaluation_economic_model_submitted">
                                    <span v-html="data.perc['economic_evaluation_economic_model_submitted']"></span>       
                                </section>
                            </div>     

                            <div class="box-toggle"  v-if="data.perc['economic_evaluation_basis_economic_model']">
                                <a class="title title-tb" @click="economic_evaluation_basis_economic_model = !economic_evaluation_basis_economic_model">                           
                                    {{$t('economic_evaluation_basis_economic_model')}} 
                                    <em :class="economic_evaluation_basis_economic_model ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="economic_evaluation_basis_economic_model" >
                                    <span v-html="data.perc['economic_evaluation_basis_economic_model']"></span>     
                                </section>
                            </div>     

                            <div class="box-toggle" v-if="data.perc['economic_evaluation_drug_costs']">
                                <a class="title title-tb" @click="economic_evaluation_drug_costs = !economic_evaluation_drug_costs">                           
                                    {{$t('economic_evaluation_drug_costs')}} 
                                    <em :class="economic_evaluation_drug_costs ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="economic_evaluation_drug_costs">
                                    <span v-html="data.perc['economic_evaluation_drug_costs']"></span>       
                                </section>
                            </div>     

                            <div class="box-toggle" v-if="data.perc['economic_evaluation_clinical_effect_estimates']">
                                <a class="title title-tb" @click="economic_evaluation_clinical_effect_estimates = !economic_evaluation_clinical_effect_estimates">                           
                                    {{$t('economic_evaluation_clinical_effect_estimates')}} 
                                    <em :class="economic_evaluation_clinical_effect_estimates ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="economic_evaluation_clinical_effect_estimates" >
                                    <span v-html="data.perc['economic_evaluation_clinical_effect_estimates']"></span>    
                                </section>
                            </div> 
                            
                            <div class="box-toggle" v-if="data.perc['economic_evaluation_cost_effectiveness_estimates']">
                                <a class="title title-tb" @click="economic_evaluation_cost_effectiveness_estimates = !economic_evaluation_cost_effectiveness_estimates">                           
                                    {{$t('economic_evaluation_cost_effectiveness_estimates')}} 
                                    <em :class="economic_evaluation_cost_effectiveness_estimates ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>
                                </a>
                                <section v-if="economic_evaluation_cost_effectiveness_estimates" >
                                    <span v-html="data.perc['economic_evaluation_cost_effectiveness_estimates']"></span>      
                                </section>
                            </div>     
                        </div>
                    </div> 
                    <!--Economic evaluation end-->

                    <!-- detail_EconomicEvaluation -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" /> 

                    <!--Adoption faisability start-->
                    <div class="content-onglets" v-else-if="key == 'detail_adoption_feasibility_considerations_implementation' && item.show">
                        <p v-html="data.perc['adoption_feasibility_considerations_implementation'] ? data.perc['adoption_feasibility_considerations_implementation'] : '-'"></p>
                    </div>
                    <!--Adoption faisability end-->

                    <!--Project history start-->
                    <div class="content-onglets table" v-else-if="key == 'detail_project_history' && item.show">
                        <table class="comparateur2 text-left border-top-gray">
                            <tbody class="noLineLR">
                                    <tr v-if="data.perc['review_status']">
                                        <td width="50%"><strong>{{$t('review_status')}}</strong></td>
                                        <td class="textJustify">{{data.perc['review_status']}}</td>
                                    </tr>

                                    <tr v-if="data.perc['pre_noc_submission'] !=0 || data.perc['pre_noc_submission']">
                                        <td width="50%"><strong>{{$t('pre_noc_submission')}}</strong></td>
                                        <td class="textJustify"> {{$t("yes_no_" + data.perc['pre_noc_submission'])}} </td>
                                    </tr>

                                    <tr v-if="data.perc['submission_deemed_complete']">
                                        <td width="50%"><strong>{{$t('submission_deemed_complete')}}</strong></td>
                                        <td class="textJustify"> {{DDMMMYY(data.perc['submission_deemed_complete'])}} </td>
                                    </tr>

                                    <tr v-if="data.perc['submission_type']">
                                        <td width="50%"><strong>{{$t('submission_type')}}</strong></td>
                                        <td class="textJustify">{{data.perc['submission_type']}}</td>
                                    </tr>

                                    <tr v-if="data.perc['prioritization_requested']">
                                        <td width="50%"><strong>{{$t('prioritization_requested')}}</strong></td>
                                        <td class="textJustify">{{data.perc['prioritization_requested']}}</td>
                                    </tr>

                                    <tr v-if="data.perc['check_point_meeting']">
                                        <td width="50%"><strong>{{$t('check_point_meeting')}}</strong></td>
                                        <td class="textJustify">{{DDMMMYY(data.perc['check_point_meeting'])}} </td>
                                    </tr>

                                    <tr v-if="data.perc['perc_meeting']">
                                        <td width="50%"><strong>{{$t('perc_meeting')}}</strong></td>
                                        <td class="textJustify">{{DDMMMYY(data.perc['perc_meeting'])}} </td>
                                    </tr>
                                    
                                    <tr v-if="data.perc['perc_reconsideration_meeting']">
                                        <td width="50%"><strong>{{$t('perc_reconsideration_meeting')}}</strong></td>
                                        <td class="textJustify">{{DDMMMYY(data.perc['perc_reconsideration_meeting'])}}</td>
                                    </tr>

                                    <tr v-if="data.perc['final_recommendation_issued_target']">
                                        <td width="50%"><strong>{{$t('final_recommendation_issued_target')}}</strong></td>
                                        <td class="textJustify">{{DDMMMYY(data.perc['final_recommendation_issued_target'])}}</td>
                                    </tr>

                                    <tr v-if="data.perc['notification_implement_issued']">
                                        <td width="50%"><strong>{{$t('notification_implement_issued')}}</strong></td>
                                        <td class="textJustify">{{DDMMMYY(data.perc['notification_implement_issued'])}}</td>
                                    </tr>

                                    <tr v-if="data.perc['perc_clarification'].length > 0">
                                        <td width="50%" style="vertical-align: top;"><strong>Clarification </strong></td>
                                        <td class="textJustify">
                                            <template v-for="(value_PercClarification, key_PercClarification) in data.perc['perc_clarification']" :key="'key_PercClarification_text_'+key_PercClarification">
                                                <span v-html="value_PercClarification['text']" ></span>
                                                <hr v-if="data.perc['perc_clarification'].length > key_PercClarification" :key="'key_PercClarification'+key_PercClarification">
                                            </template>
                                        </td>
                                    </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--Project history end-->

                    <!--PDF start-->
                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>
                    <!--PDF end-->

                    <!--Context of the resubmission start-->
                    <div class="content-onglets textJustify" v-else-if="key == 'detail_context_of_the_resubmission' && item.show">
                        <p v-html="data.perc['context_of_the_resubmission']"></p>
                    </div>    
                    <!--Context of the resubmission end-->    

                    <!--Target population start-->
                    <div class="content-onglets" v-else-if="key == 'detail_target_population_number' && item.show">                      
                        <section>{{data.perc['target_population_number'] ? data.perc['target_population_number'] : '-'}}</section>             
                        <section v-html="data.perc['target_population_text'] ? data.perc['target_population_text'] : '-'"></section>
                    </div>
                    <!--Target population end-->
                    <!-- Section Detail END -->

                </div>
            </template>

            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->

        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue';
import TreatmentLine from '../treatment_line.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import Conclusion from '../conclusion.vue'
import LinkAgency from '../LinkAgency.vue'
import KeyDocument from '../key_document.vue'
import Indication from '../Indication.vue'
import { DDMMMYY, check_detail_conclusion, key_documents } from '../../../utils'

export default{
    name: 'perc',
    components : {
        LeftSection,
        TreatmentLine,
        EconomicEvaluation,
        Conclusion,
        LinkAgency,
        EssaisClinique,
        KeyDocument,
        Indication
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_general_information : { 'title' : 'general_information', 'show' : true, 'enable' : false, 'orange_text':false},               
                detail_indication : { 'title' : 'indication', 'show' : true, 'enable' : false, 'orange_text':false},               
                detail_funding_request : { 'title' : 'Funding request', 'show' : true, 'enable' : false, 'orange_text':false},               
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true },
                detail_perc_recommendation : {'title' : 'perc_recommendation', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_potential_next_steps : {'title' : 'potential_next_steps', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_summary_of_perc_deliberation : {'title' : 'summary_of_perc_deliberation', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_evidence_in_brief : {'title' : 'evidence_in_brief', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_contextual_information : {'title' : 'Contextual Information', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_overall_clinical_benefit_pcodr_review_scope : {'title' : 'overall_clinical_benefit', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true },             
                detail_patient_based_values_experiences_patients : {'title' : 'patient_based_values', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_PercEffectivenessEvidence : {'title' : 'Cost effectiveness Evidence', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_economic_evaluation_economic_model_submitted : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_EconomicEvaluation : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true },           
                detail_adoption_feasibility_considerations_implementation : {'title' : 'adoption_feasibility_considerations_implementation', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_project_history : {'title' : 'Project history', 'show' : true, 'enable' : true, 'orange_text':false },                
                detail_context_of_the_resubmission : {'title' : 'context_of_the_resubmission', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_target_population_number : {'title' : 'target_population', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false },                
            },
            overall_clinical_benefit_pcodr_review_scope : false,
            overall_clinical_benefit_studies_included : false,
            overall_clinical_benefit_patient_populations : false,
            overall_clinical_benefit_key_efficacy_result : false,
            overall_clinical_benefit_patient_reported_outcomes : false,
            overall_clinical_benefit_duration_of_therapy : false,
            overall_clinical_benefit_quality_of_life : false,
            overall_clinical_benefit_safety : false,
            overall_clinical_benefit_limitations : false,
            overall_clinical_benefit_ongoing_trials : false,
            overall_clinical_benefit_comparator_information : false,
            overall_clinical_benefit_registered_clinician : false,
            overall_clinical_benefit_need : false,

            patient_based_values_experiences_patients : false,
            patient_based_values_values_of_patients : false,
            patient_based_values_patient_values_treatment : false,

            economic_evaluation_economic_model_submitted : false,
            economic_evaluation_basis_economic_model : false,
            economic_evaluation_drug_costs : false,
            economic_evaluation_clinical_effect_estimates : false,
            economic_evaluation_cost_effectiveness_estimates : false,

            overall_clinical_benefit_previously_treated_patients : false
            
        }
    },
    created(){
        // condition to show check box in left section [enable]
        if(this.data && this.data.perc){
            
            if(this.data.guidance_id || this.data.status){
                this.list_check_box['detail_general_information'].enable = true
            } 
            if(this.data.perc['funding_request'])
            { 
                this.list_check_box['detail_funding_request'].enable = true
            }
            if(this.data['indication_en'])
            { 
                this.list_check_box['detail_indication'].enable = true
            }
            if(this.data.treatment_line || this.data.specificity || this.data.administration)  
            { 
                this.list_check_box['treatment_line'].enable = true
            }
            if(this.check_detail_conclusion(this.data, this.$i18n.locale)) 
            { 
                this.list_check_box['detail_Conclusion'].enable = true
            }
            if(this.data.perc['perc_recommendation'])
            { 
                this.list_check_box['detail_perc_recommendation'].enable = true 
            } 
            if(this.data.perc['potential_next_steps'])
            { 
                this.list_check_box['detail_potential_next_steps'].enable = true 
            }
            if(this.data.perc['summary_of_perc_deliberation'])
            { 
                this.list_check_box['detail_summary_of_perc_deliberation'].enable = true 
            } 
            if(this.data.perc['evidence_in_brief'])
            { 
                this.list_check_box['detail_evidence_in_brief'].enable = true 
            } 
            if(this.data.perc['contextual_information'])
            { 
                this.list_check_box['detail_contextual_information'].enable = true 
            }  
            if(this.data.perc['overall_clinical_benefit_pcodr_review_scope'] || 
                this.data.perc['overall_clinical_benefit_studies_included'] ||
                this.data.perc['overall_clinical_benefit_patient_populations'] ||
                this.data.perc['overall_clinical_benefit_key_efficacy_result'] ||
                this.data.perc['overall_clinical_benefit_patient_reported_outcomes'] ||
                this.data.perc['overall_clinical_benefit_duration_of_therapy'] ||
                this.data.perc['overall_clinical_benefit_quality_of_life'] ||
                this.data.perc['overall_clinical_benefit_safety'] ||
                this.data.perc['overall_clinical_benefit_limitations'] ||
                this.data.perc['overall_clinical_benefit_ongoing_trials'] ||
                this.data.perc['overall_clinical_benefit_comparator_information'] ||
                this.data.perc['overall_clinical_benefit_previously_treated_patients'] ||
                this.data.perc['overall_clinical_benefit_registered_clinician'] ||
                this.data.perc['overall_clinical_benefit_need'] )
            { 
                this.list_check_box['detail_overall_clinical_benefit_pcodr_review_scope'].enable = true 
            } 
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            } 
            if(this.data.perc['patient_based_values_experiences_patients'] ||
                this.data.perc['patient_based_values_values_of_patients'] ||
                this.data.perc['patient_based_values_patient_values_treatment'])
            { 
                this.list_check_box['detail_patient_based_values_experiences_patients'].enable = true 
            }    
            if(this.data.perc['perc_effectiveness_evidence'].length > 0)
            { 
                this.list_check_box['detail_PercEffectivenessEvidence'].enable = true 
            }  
            if(this.data.perc['economic_evaluation_economic_model_submitted'] || this.data.perc['economic_evaluation_basis_economic_model'] ||
                this.data.perc['economic_evaluation_drug_costs'] || this.data.perc['economic_evaluation_cost_effectiveness_estimates'] ||
                this.data.perc['economic_evaluation_clinical_effect_estimates'])
            { 
                this.list_check_box['detail_economic_evaluation_economic_model_submitted'].enable = true 
            }    
                            
            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                this.list_check_box['detail_EconomicEvaluation'].enable = true
            } 
            if(this.data.perc['adoption_feasibility_considerations_implementation'])
            { 
                this.list_check_box['detail_adoption_feasibility_considerations_implementation'].enable = true 
            } 
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0)
            {
                this.list_check_box['detail_key_documents'].enable = true
            }
            if(this.data.perc['context_of_the_resubmission'])
            { 
                this.list_check_box['detail_context_of_the_resubmission'].enable = true 
            } 
            if(this.data.perc['target_population_number'] || this.data.perc['target_population_text'])
            { 
                this.list_check_box['detail_target_population_number'].enable = true 
            }

        }
    },
    methods : {
        DDMMMYY,
        check_detail_conclusion,
        key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        FULL_BASE_URL(){
            return process.env.VUE_APP_URL_PRISMACCESS
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }      
    },
}

</script>

<style scoped>

a {
    cursor: pointer;
}
.fa-angle-up {
    color : #ff6300;
}
.box-toggle a:hover i {
    color: #ff6300;
}
section {
    margin-top : 10px;
    margin-bottom : 10px;
}
</style>